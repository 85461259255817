<template>
  <ul class="lang-switcher">
    <li
        v-for="locale in supportedLocales"
        :key="locale"
        @click="switchLocale(locale)"
        v-bind:class="{'lang-switcher__item_active': locale === $i18n.locale}"
        class="lang-switcher__item"
    >
      <div class="lang-switcher__image">
        <ImageApp
            :path="locale + '.png'"
            :alt="locale"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import {Trans} from '@/plugins/Translation'
import ImageApp from "@/components/ImageApp";

export default {
  name: 'LocaleSwitcher',
  components: {ImageApp},
  computed: {
    supportedLocales() {
      return Trans.supportedLocales
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({params: {locale}})
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.lang-switcher {
  display: flex;
  border: 3px solid rgb(103, 157, 43);
  border-radius: 5px;
  margin: 0 30px 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }

  &__item {
    cursor: pointer;
    border-right: 3px solid rgb(103, 157, 43);
    overflow: hidden;

    &_active {
      pointer-events: none;
    }

    &:last-child {
      border-right: 0;
    }
  }

  &__image {
    width: 24px;
    height: 16px;
  }
}
</style>