<template>
  <header class="header">
    <div class="header__container content-container">
      <div class="header__logotype">
        <ImageApp
            :path="'logotype.png'"
            :alt="'Global meat trading'"
        />
      </div>

      <nav class="header__menu">
        <div class="burger" v-on:click="burger = !burger" v-bind:class="{'open': burger}">
          <span></span><span></span><span></span>
        </div>

        <div class="header__menu-dropdown" v-bind:class="{'open': burger}">
          <router-link :to="$i18nRoute({ name: 'Home' })">{{ $t('menu.home') }}</router-link>
          <router-link :to="$i18nRoute({ name: 'About' })">{{ $t('menu.about') }}</router-link>
          <router-link :to="$i18nRoute({ name: 'Products' })">{{ $t('menu.products') }}</router-link>
        </div>
      </nav>

      <LocaleSwitcher/>
    </div>
  </header>
</template>

<script>
import ImageApp from "@/components/ImageApp";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "HeaderApp",
  components: {LocaleSwitcher, ImageApp},
  data: function (){
    return {
      burger: false
    }
  }
}
</script>

<style lang="scss">
.header {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .content-container {
    padding: 57px 20px 46px;
  }

  &__logotype {
    display: inline-block;
    width: 70px;
    height: 70px;

    @media (min-width: 768px) {
      width: 117px;
      height: 117px;
    }

    & img {
      max-width: 100%;
    }
  }

  &__menu {
    order: 3;
    position: relative;
    width: 22px;

    @media (min-width: 768px) {
      order: unset;
      width: 55%;
    }

    @media (min-width: 1024px) {
      order: unset;
      max-width: 665px;
      width: 65%;
    }

    &-dropdown {
      position: absolute;
      max-width: 200px;
      width: 100vw;
      right: 0;
      top: calc(100% + 10px);
      padding: 20px;
      text-align: right;
      flex-direction: column;
      background-color: #fff;
      white-space: nowrap;
      border-radius: 10px;
      display: none;
      transition: opacity .3s ease-in-out;

      @media (min-width: 768px) {
        max-width: unset;
        width: unset;
        text-align: unset;
        padding: 0;
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
      }

      &.open {
        display: flex;
      }
    }

    & a {
      color: #000;
      font: 700 25px 'Lato-Black', sans-serif;
      text-transform: uppercase;
      transition: color .3s ease-in-out;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      @media (min-width: 1024px) {
        font: 700 30px 'Lato-Black', sans-serif;
      }

      &.router-link-exact-active {
        color: #ff3a37;
      }
    }

    & .burger {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 22px;
      height: 22px;
      cursor: pointer;

      @media (min-width: 768px) {
        display: none;
      }

      & span {
        display: inline-block;
        height: 2px;
        width: 100%;
        background-color: red;
        transition: all .3s ease-in-out;
      }

      &.open {

        & span {
          position: absolute;

          &:nth-child(1) {
            top: 11px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            top: 11px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>