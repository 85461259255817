import Vue from 'vue'
import App from "@/App";
import router from '@/router'
import {i18n} from '@/i18n'
import {Trans} from '@/plugins/Translation'
import Vuelidate from 'vuelidate'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import '@/assets/css/common.scss'
import '@/assets/css/icons.scss'
import '@/assets/fonts/fonts.css'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueTelInput)

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')