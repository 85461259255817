<template>
  <footer class="footer">
    <div class="content-container footer__container">
      <div class="footer__top">
        <div class="footer__logotype">
          <ImageApp
              :path="'logotype.png'"
              :alt="'Global meat trading'"
          />
        </div>

        <div class="footer__list">
          <div class="footer__item footer__item_ru">
            <p>Moscow Head Office</p>
            <p>Global Meat Traders LLC</p>
            <p>Township Sosenskoe, 22nd kilometer, Kaluzhskoe Higway, Building №10</p>
            <a href="tel:+74954800675">+7 495 480-06-75</a>
            <a href="mailto:info@meatglobal.eu">
              <span class="icon icon__mail"></span>
              info@meatglobal.eu
            </a>
          </div>

          <div class="footer__item footer__item_lt">
            <p>Vilnius Export Office</p>
            <p>Global Meat Traders LLC</p>
            <p>Lithuania <br> Address: Lvovo g. 13-12, LT-09313 Vilnius</p>
            <a href="tel:+37067698503">
              <span class="icon icon__phone"></span>
              +37067698503
            </a>
            <a href="mailto:sales@meatglobal.eu">
              <span class="icon icon__mail"></span>
              sales@meatglobal.eu
            </a>
          </div>

          <div class="footer__item footer__item_cn">
            <p>Hong Kong Coldstore</p>
            <p>Cold Storage Address: Sun Fung Centre, 88 Kwok Shui Road, Kwai Chung</p>
            <p></p>
          </div>

          <div class="footer__item footer__item_vn">
            <p>Haiphong, Vietnam Coldstore</p>
            <p>Cold Storage Address: KB1-1, Nam Dinh Vu Industrial Zone (Zone 1), Dong Hai 2 Wards, Hai An Districts</p>
            <p></p>
          </div>
        </div>
      </div>

      <p class="copyright">Global Meat Traders © 2021</p>
    </div>
  </footer>
</template>

<script>
import ImageApp from "@/components/ImageApp";

export default {
  name: "FooterApp",
  components: {ImageApp}
}
</script>

<style lang="scss">
.footer {
  padding: 20px 0;
  background-color: rgb(93, 93, 93);

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__logotype {
    width: 117px;
    height: 117px;
    display: inline-block;
    margin-bottom: 30px;

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;

    @media (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1200px) {
      max-width: calc(100% - 120px - 40px);
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;
      margin-bottom: 0;
    }
  }

  &__item {
    height: 160px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    color: #000;
    font: 400 11px "Lato-Regular";
    text-align: center;
    padding: 10px 5px;
    border: 1px solid #000;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, .7);
      border-radius: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 5px;
      top: 5px;
      background: url("../assets/image/icon/icon__location.png") no-repeat center;
      background-size: cover;
      width: 22px;
      height: 21px;
    }

    &_ru {
      background: url("../assets/image/background__ru.jpg") no-repeat center;
      background-size: cover;
    }

    &_lt {
      background: url("../assets/image/background__lt.jpg") no-repeat center;
      background-size: cover;
    }

    &_cn {
      background: url("../assets/image/background__cn.jpg") no-repeat center;
      background-size: cover;
    }

    &_vn {
      background: url("../assets/image/background__vn.jpg") no-repeat center;
      background-size: cover;
    }

    & p:first-child {
      font: 600 11px "Lato-Black";
    }

    & p, & a {
      position: relative;
      color: #000;
    }

    & p:first-child {
      font-size: 16px;

      &:first-letter {
        color: #ff0000;
      }
    }
  }

  &__contact {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    grid-gap: 20px;
    color: #fff;
    font: 400 16px "Lato-Regular", sans-serif;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
      max-width: 175px;
      grid-template-columns: 1fr;
      text-align: right;
    }

    & a {
      color: #fff;
    }
  }
}

.copyright {
  margin-top: 30px;
  font: 400 16px "Lato-Regular", sans-serif;
  color: #fff;
  text-align: center;
}
</style>