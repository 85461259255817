<template>
  <div id="app">
    <header-app/>
    <main>
      <div class="content">
        <router-view/>
      </div>
    </main>
    <footer-app/>
  </div>
</template>

<script>
import HeaderApp from "@/components/HeaderApp";
import FooterApp from "@/components/FooterApp";

export default {
  name: 'app',
  components: {HeaderApp, FooterApp},
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration-color: transparent;
  box-sizing: border-box;
}

#app {
  font: 400 14px 'Lato-Regular', sans-serif;
  color: #fff;
}

.content {

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {

  }
}
</style>